<template>
  <div style="width: 40rem !important; margin-top:10px!important">
    <div class="card" :class="$style.container">
      <div class="text-center font-size-24 mb-4" :class="$style.colorPrimary">
        <strong> {{ $t('authForm.resetPass') }} </strong>
      </div>
      <div v-if="!isSuccessReset">
        <a-form class="mb-4" :form="form" @submit="handleSubmit">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('authForm.newPass') }}</label>
            <a-input-password
              size="large"
              :placeholder="$t('authForm.placeholderPassword')"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: true,
                      min: 8,
                      message: $t('authForm.minRequiredPassword'),
                      pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),
                    },
                  ],
                }
              ]"
            />
          </a-form-item>
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('authForm.confirmNewPass') }}</label>
            <a-input-password
              size="large"
              :placeholder="$t('authForm.confirmPassword')"
              type="password"
              v-decorator="[
                'confirmpassword',
                {
                  rules: [
                    {
                      validator: (rule, value, callback) => value && value !== form.getFieldValue('password') ? callback($t('form.confirmPasswordNotMatch')) : callback(),
                      message: $t('form.confirmPasswordNotMatch'),
                    },
                  ],
                }
              ]"
            />
          </a-form-item>
          <div>
            <a-button
              htmlType="submit"
              size="large"
              type="primary"
              class="text-center w-100"
              :loading="loading"
            >
              <strong> {{ $t('store.save') }} </strong>
            </a-button>
          </div>
        </a-form>
      </div>
      <div v-if="isSuccessReset">
        <a-alert
          :message="msgAlert.message"
          :description="msgAlert.description"
          type="success"
          show-icon
          closable
          :after-close="() => isSuccessReset = false"
        />
      </div>
      <div class="mt-3">
        <router-link to="/auth/login" class="kit__utils__link font-size-16" :class="$style.colorPrimary">
          {{ $t('authForm.goToSignIn') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CuiResetPassword',
  computed: {
    ...mapState(['settings']),
    ...mapGetters({ authorized: 'user/authorized' }),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      msgAlert: {
        message: '',
        description: '',
      },
      isSuccessReset: false,
      dataToUpdate: null,
      rules: {
        password: [
          { required: true, message: 'Password is required' },
        ],
        confirm_password: [
          { required: true, message: 'Confirm password is required' },
          {
            // eslint-disable-next-line standard/no-callback-literal
            validator: (rule, value, callback) => value && value !== this.form.getFieldValue('password') ? callback('Confirm password does not match') : callback(),
            message: this.$t('form.confirmPasswordNotMatch'),
          },
        ],
      },
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) return

        this.$store.dispatch('user/CHANGEPASSWORD', {
          register_link: this.dataToUpdate.link,
          new_password: values.password,
          confirm_password: values.confirmpassword,
          token: this.$route.params.token,
        })
          .then(() => {
            this.isSuccessReset = true
            this.msgAlert = {
              message: this.$t('authForm.thankYou'),
              description: this.$t('authForm.resetpassSuccess'),
            }
          })
          .catch((err) => {
            const { data } = err.response
            this.$notification.error({
              message: this.$t('authForm.forgotpassFailed'),
              description: data && data.message ? data.message : 'Internal Server Error',
            })
          })
      })
    },
  },
  created() {
    this.$store.dispatch('user/CONFIRMLINKFORGOTPASSWORD', {
      hashId: this.$route.params.hashId,
      token: this.$route.params.token,
    })
      .then((response) => {
        this.dataToUpdate = response
      })
      .catch(err => {
        console.log(err.response?.data?.message || err.message)
        this.$router.push('/link-expired')
      })
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>

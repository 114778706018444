var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"40rem !important","margin-top":"10px!important"}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-center font-size-24 mb-4",class:_vm.$style.colorPrimary},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('authForm.resetPass'))+" ")])]),(!_vm.isSuccessReset)?_c('div',[_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('authForm.newPass')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: true,
                    min: 8,
                    message: _vm.$t('authForm.minRequiredPassword'),
                    pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),
                  },
                ],
              }
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    min: 8,\n                    message: $t('authForm.minRequiredPassword'),\n                    pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),\n                  },\n                ],\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('authForm.placeholderPassword'),"type":"password"}})],1),_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('authForm.confirmNewPass')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirmpassword',
              {
                rules: [
                  {
                    validator: (rule, value, callback) => value && value !== _vm.form.getFieldValue('password') ? callback(_vm.$t('form.confirmPasswordNotMatch')) : callback(),
                    message: _vm.$t('form.confirmPasswordNotMatch'),
                  },
                ],
              }
            ]),expression:"[\n              'confirmpassword',\n              {\n                rules: [\n                  {\n                    validator: (rule, value, callback) => value && value !== form.getFieldValue('password') ? callback($t('form.confirmPasswordNotMatch')) : callback(),\n                    message: $t('form.confirmPasswordNotMatch'),\n                  },\n                ],\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('authForm.confirmPassword'),"type":"password"}})],1),_c('div',[_c('a-button',{staticClass:"text-center w-100",attrs:{"htmlType":"submit","size":"large","type":"primary","loading":_vm.loading}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('store.save'))+" ")])])],1)],1)],1):_vm._e(),(_vm.isSuccessReset)?_c('div',[_c('a-alert',{attrs:{"message":_vm.msgAlert.message,"description":_vm.msgAlert.description,"type":"success","show-icon":"","closable":"","after-close":() => _vm.isSuccessReset = false}})],1):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('router-link',{staticClass:"kit__utils__link font-size-16",class:_vm.$style.colorPrimary,attrs:{"to":"/auth/login"}},[_vm._v(" "+_vm._s(_vm.$t('authForm.goToSignIn'))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }